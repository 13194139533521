import filter from 'lodash/filter'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import words from 'lodash/words'
import clone from 'lodash/clone'
import head from 'lodash/head'
import range from 'lodash/range'

import moment from 'moment'

import store from '@/store'

const daysOfWeek = () => {
	return [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	]
}

const classStatuses = () => {
	return[
		'Active', 'Discontinued'
	]
}

const DancerStatusTypes = {
	Active: 'Active',
	Paused: 'Paused',
	Archived: 'Archived'
}

const dancerStatuses = () => {
	return [
		DancerStatusTypes.Active,
		DancerStatusTypes.Paused,
		DancerStatusTypes.Archived
	]
}

const DancerBillingType = {
	Subscriber: 'Subscriber',
	Nonsubscriber: 'Non-Subscriber'
}

const dancerBillingTypes = () => {
	return [
		DancerBillingType.Subscriber,
		DancerBillingType.Nonsubscriber
	]
}

const classTemplate = () => {
	return {
		statuses: classStatuses(),
		daysOfWeek: daysOfWeek(),
		maxDancers: range(1, 10),
		maxRegisters: range(1, 10),
		maxMakeups: range(0, 10)
	}
}

const defaultClass = (template) => {
	return {
		status: head(template.statuses),
		dayOfWeek: head(template.daysOfWeek),
		classLevel: null,
		classType: null,
		length: 0,
		instructor: null,
		studio: null,
		startTime: moment().hour(10).minute(0).toDate(),
		startDate: moment().toDate(),
		endDate: null,
		ongoing: false,

		maxDancers: 4,
		maxRegisters: 3,
		maxMakeups: 1
	}
}

import eventActionTypes from './eventActions'

const eventActions = () => {
	return [
		eventActionTypes.JoinClass,
		eventActionTypes.LeaveClass
	]
}

const isClassEventAction = (action) => {
	switch (action)
	{
		case eventActionTypes.JoinClass:
		case eventActionTypes.LeaveClass:
		case eventActionTypes.MissClass:
		case eventActionTypes.MakeupClass:
			return true

		default:
			return false
	}
}

const formatClassLength = (mins) => {
	if (mins <= 90) {
		return mins + ' mins'
	}

	return (mins / 60) + ' hours'
}

const filterClasses = (classes, phrase) => {
	const phrases = words(phrase)

	var list = clone(classes)

	for (var i = 0; i < phrases.length; ++i) {
		const phrase = toLower(phrases[i])
		list = filter(list, obj => {
			return includes(toLower(obj.dayOfWeek), phrase) ||
				includes(toLower(obj.status), phrase) ||
				includes(toLower(store.getters.studio(obj.studio).name), phrase) ||
				includes(toLower(store.getters.classType(obj.classType).name), phrase) ||
				includes(toLower(store.getters.classLevel(obj.classLevel).name), phrase) ||
				includes(toLower(store.getters.teacher(obj.instructor).name), phrase) ||
				includes(toLower(obj.startTime), phrase)
		})
	}

	return list
}

const filterDancers = (dancers, phrase) => {
	const phrases = words(phrase)

	var list = clone(dancers)

	for (var i = 0; i < phrases.length; ++i) {
		const phrase = phrases[i]
		list = filter(list, obj => {
			return includes(toLower(obj.firstName), phrase) ||
				includes(toLower(obj.lastName), phrase) ||
				includes(toLower(obj.email), phrase) ||
				includes(toLower(obj.status), phrase)
		})
	}

	return list
}

export { 
	daysOfWeek,
	classStatuses,

	DancerStatusTypes,
	dancerStatuses,

	DancerBillingType,
	dancerBillingTypes,

	classTemplate,
	defaultClass,
	
	formatClassLength,

	filterClasses,
	filterDancers,

	eventActionTypes,
	eventActions,
	isClassEventAction
}
