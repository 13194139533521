import Vue from 'vue'

import findIndex from 'lodash/findIndex'

const state = {
	eventsForDancer: {}
}

const getters = {
	forDancer: state => uid => {
		if (!(uid in state.eventsForDancer))
		{
			return []
		}
		return state.eventsForDancer[uid]
	},
}

const mutations = {
	add: (state, data) => {
		const dancerId = data.dancerId
		const event = data.event

		if (!(dancerId in state.eventsForDancer))
		{
			Vue.set(state.eventsForDancer, dancerId, [])
		}

		var length = state.eventsForDancer[dancerId].length
		Vue.set(state.eventsForDancer[dancerId], length, event)
	},
	update: (state, data) => {
		const dancerId = data.dancerId
		const event = data.event

		var idx = findIndex(state.eventsForDancer[dancerId], item => item.uid === event.uid)
		if (idx < 0) {
			return
		}

		Vue.set(state.eventsForDancer[dancerId], idx, event)
	},
	remove: (state, data) => {
		const dancerId = data.dancerId
		const event = data.event

		var idx = findIndex(state.eventsForDancer[dancerId], item => item.uid === event.uid)
		if (idx < 0) {
			return
		}

		Vue.delete(state.eventsForDancer[dancerId], idx)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
