import Vue from 'vue'

// eslint-disable-next-line
import { Module } from 'vuex'

interface PledgeData
{
	email: string
	pledge: number
	charged: boolean
}

interface IState
{
	pledges: PledgeData[]
}

const module: Module<IState, {}> = {
	namespaced: true,

	state: {
		pledges: []
	},

	getters: {
		pledges: state => state.pledges
	},

	mutations: {
		add: (state, data: PledgeData) => {
			state.pledges.push(data)
		},
		update: (state, data: PledgeData) => {
			const idx = state.pledges.findIndex(p => p.email === data.email)
			if (idx < 0)
			{
				return
			}

			Vue.set(state.pledges, idx, data)
		},
		remove: (state, data: PledgeData) => {
			const idx = state.pledges.findIndex(p => p.email === data.email)
			if (idx < 0)
			{
				return
			}

			Vue.delete(state.pledges, idx)
		}
	}
}

export default module