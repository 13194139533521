import Vue from 'vue'

// eslint-disable-next-line
import { Module } from 'vuex'

import findIndex from 'lodash/findIndex'

import moment from 'moment'

interface IState
{
	substitutesForClass: { [key: string]: ISubstitute[] }
	substitutesByDate: { [key: string]: ISubstitute[] }
	substitutes: ISubstitute[]
}

const dateKeyFormat = (date: Date | moment.Moment) => {
	const f = 'YYYY-MM-DD'

	if (!moment.isMoment(date))
	{
		return moment(date).format(f)
	}

	return date.format(f)
}

const module: Module<IState, {}> = {
	namespaced: true,

	state: {
		substitutesForClass: {},
		substitutesByDate: {},
		substitutes: []
	},

	getters: {
		forClass: (state: any) => (uid: string) => {
			if (!(uid in state.substitutesForClass))
			{
				return []
			}
			return state.substitutesForClass[uid]
		},
		forDate: (state: any) => (date: Date) => {
			const key = dateKeyFormat(date)
			if (!(key in state.substitutesByDate))
			{
				return []
			}
	
			return state.substitutesByDate[key]
		},
		substitutes: (state: any) => {
			return state.substitutes
		},
		substitutesByDate: (state: any) => {
			return state.substitutesByDate
		}
	},

	mutations: {
		add: (state: any, data: any) => {
			const classId = data.classId
			const sub: ISubstitute = data.sub
	
			if (!(classId in state.substitutesForClass))
			{
				Vue.set(state.substitutesForClass, classId, [])
			}
	
			let length = state.substitutesForClass[classId].length
			Vue.set(state.substitutesForClass[classId], length, sub)
	
			const key = sub.uid // this is in date format
			if (!(key in  state.substitutesByDate))
			{
				Vue.set(state.substitutesByDate, key, [])
			}
	
			length = state.substitutesByDate[key].length
			Vue.set(state.substitutesByDate[key], length, sub)
	
			state.substitutes.push(sub)
		},
		update: (state: any, data: any) => {
			const classId = data.classId
			const sub: ISubstitute = data.sub
	
			let idx = findIndex(state.substitutesForClass[classId], (item: any) => item.uid === sub.uid)
			if (idx >= 0)
			{
				Vue.set(state.substitutesForClass[classId], idx, sub)
			}
	
			const key = sub.uid
			idx = findIndex(state.substitutesByDate[key], (item: any) => item.classId === sub.classId)
			if (idx >= 0)
			{
				Vue.set(state.substitutesByDate[key], idx, sub)
			}
	
			idx = findIndex(state.substitutes, (item: any) => item.uid === sub.uid && item.classId === sub.classId)
			if (idx >= 0)
			{
				Vue.set(state.substitutes, idx, sub)
			}
			
		},
		remove: (state: any, data: any) => {
			const classId = data.classId
			const sub: ISubstitute = data.sub
	
			let idx = findIndex(state.substitutesForClass[classId], (item: any) => item.uid === sub.uid)
			if (idx >= 0)
			{
				Vue.delete(state.substitutesForClass[classId], idx)
			}
	
			const key = sub.uid
			idx = findIndex(state.substitutesByDate[key], (item: any) => item.classId === sub.classId)
			if (idx >= 0)
			{
				Vue.delete(state.substitutesByDate[key], idx)
			}
	
			idx = findIndex(state.substitutes, (item: any) => item.uid === sub.uid && item.classId === sub.classId)
			if (idx >= 0)
			{
				Vue.delete(state.substitutes, idx)
			}
		}
	}
}

export default module
