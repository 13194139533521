
const Pointe = 'Pointe'
const JumpsAndTurns = 'Jumps and Turns'
const TeenTechnique = 'Teen Technique'
const BalletTechnique = 'Ballet Technique'
const Stretch = 'Stretch'
const Prepointe = 'Pre-pointe'
const LowImpact = 'Low Impact'

export {
	Pointe,
	JumpsAndTurns,
	TeenTechnique,
	BalletTechnique,
	Stretch,
	Prepointe,
	LowImpact
}
