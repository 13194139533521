import { firestore } from '@/firebase'

const payroll = firestore.collection('payroll')
const classOverridesByDate = firestore.collection('class-overrides-by-date')
const releves = firestore.collection('releve-pledges')

export {
	payroll,
	classOverridesByDate,
	releves
}
