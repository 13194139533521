import eventsByClass from 'studio-shared/store/modules/eventsByClass'
import missedClassesByDate from 'studio-shared/store/modules/missedClassesByDate'
import makeupsByDate from 'studio-shared/store/modules/makeupsByDate'
import metrics from 'studio-shared/store/modules/metrics'
import billing from 'studio-shared/store/modules/billing'
import closures from 'studio-shared/store/modules/closures'
import extraCredits from 'studio-shared/store/modules/extraCredits'
import freeClasses from 'studio-shared/store/modules/freeClasses'
import freeClassesByDate from 'studio-shared/store/modules/freeClassesByDate'

import store from 'studio-shared/store'

import archivedEvents from './modules/archivedEvents'
import substitutes from './modules/substitutes'
import releves from './modules/releves'

store.registerModule('eventsByClass', eventsByClass)
store.registerModule('missedClassesByDate', missedClassesByDate)
store.registerModule('makeupsByDate', makeupsByDate)
store.registerModule('metrics', metrics)
store.registerModule('billing', billing)
store.registerModule('closures', closures)
store.registerModule('extraCredits', extraCredits)
store.registerModule('freeClasses', freeClasses)
store.registerModule('freeClassesByDate', freeClassesByDate)

store.registerModule('archivedEvents', archivedEvents)
store.registerModule('substitutes', substitutes)
store.registerModule('releves', releves)

export default store
