import map from 'lodash/map'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'

import { eventActionTypes, isClassEventAction, } from '@/utils'

import { classesForDancer, activeAndJoinClassesForDancer } from 'studio-shared/classes/classesForDancer'

import store from '@/store'

const getActiveDancersForClass = (data) => {
	return filter(
		map(
			store.getters.dancersForClass(data.uid),
			uid => {
				const d = store.getters.dancer(uid)

				// if (!d)
				// {
				// 	console.log(data)
				// 	console.log(uid)
				// }

				return d
			}
		),
		item => item
	)
}

const numDancersForClass = (data) => {
	const dancers = getActiveDancersForClass(data)
	if (!dancers) {
		return 0
	}

	return dancers.length
}

const dancersForClass = (data, callback) => {
	const classEvents = filter(
		store.getters.eventsForClass(data.uid),
		item => isClassEventAction(item.event.action)
	)

	const transformEvent = (item) => {
		var d = cloneDeep(store.getters.dancer(item.dancerId))
		
		if (!d)
		{
			d =  {
				uid: item.dancerId
			}
		}

		d.event = item.event
		return d
	}

	const joiningDancers = map(
		remove(classEvents, item => item.event.action === eventActionTypes.JoinClass),
		transformEvent
	)

	const leavingDancers = map(classEvents, transformEvent)

	const activeDancers = filter(
		getActiveDancersForClass(data),
		item => findIndex(leavingDancers, ld => ld.uid === item.uid) < 0
	)

	if (callback)
	{
		callback(leavingDancers, joiningDancers, activeDancers)
	}

	return concat(activeDancers, joiningDancers, leavingDancers)
}

export {
	classesForDancer,
	activeAndJoinClassesForDancer,

	numDancersForClass,
	dancersForClass
}