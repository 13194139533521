import store from '@/store'

import filter from 'lodash/filter'

import * as ClassTypes from './classTypes'

const ClassLevelTypes = {
	Ballet: 'Ballet',
	Pointe: 'Pointe',
	Open: 'Open'
}

const classTypesForLevel = (level) => {
	const classTypes = store.getters.classTypes

	return filter(classTypes, type => {
		switch (type.name)
		{
			case ClassTypes.BalletTechnique:
			case ClassTypes.LowImpact:
			case ClassTypes.JumpsAndTurns:
			case ClassTypes.TeenTechnique:
				return level.levelType === ClassLevelTypes.Ballet

			case ClassTypes.Pointe:
				return level.levelType === ClassLevelTypes.Pointe

			default:
				return level.levelType === ClassLevelTypes.Open
		}
	})
}

export {
	ClassLevelTypes,

	classTypesForLevel
}
