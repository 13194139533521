import {
	studioNameFromUid,
	teacherNameFromUid,
	classTypeFromUid,
	classLevelFromUid
} from '@/utils/nameFromUid'

import moment from 'moment'

const registrationFormat = (item, includeStarting) => {
	if (!item) 
	{
		return '--'
	}

	const parts = [
		item.dayOfWeek + 's',
		'at',
		item.startTime.replace(' ', ''),
		'with',
		teacherNameFromUid(item.instructor),
		'at',
		studioNameFromUid(item.studio)
	]

	if (!item.startDate || !includeStarting)
	{
		return parts.join(' ')
	}

	const startDate = moment(item.startDate)
	const now = moment()

	if (startDate.isBefore(now, 'day'))
	{
		return parts.join(' ')
	}

	parts.push('starting')
	parts.push(startDate.format('dddd, MMMM Do, YYYY'))

	return parts.join(' ')
}

const makeupFormat = (item) => {
	if (!item)
	{
		return '--'
	}

	const parts = [
		item.dayOfWeek,
		'@',
		item.startTime.replace(' ', ''),
		classTypeFromUid(item.classType),
		'(' + classLevelFromUid(item.classLevel) + ')',
		'with',
		teacherNameFromUid(item.instructor),
		'at',
		studioNameFromUid(item.studio)
	]
	return parts.join(' ')
}

export {
	registrationFormat,
	makeupFormat
}
