import { 
	initialize, 
	firestore as db, 
	collections 
} from 'studio-shared/firebase'

import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/auth'

import config from './config'

const firebaseApp = initialize(config)

const firestore: firebase.firestore.Firestore = db()
const functions = firebase.functions()
const auth = firebase.auth()

export default firebaseApp

export { 
	firestore,
	functions,
	auth,
	collections
}
