import store from '@/store'

const studioNameFromUid = (uid) => {
	if (!uid) 
	{
		return 'Missing studio'
	}
	return store.getters.studio(uid).name
}

const classTypeFromUid = (uid, returnObject = false) => {
	if (!uid) 
	{
		return 'Missing class type'
	}

	const data = store.getters.classType(uid)

	if (returnObject)
	{
		return data
	}

	return data.name
}

const classLevelFromUid = (uid) => {
	if (!uid) 
	{
		return 'Missing class level'
	}
	return store.getters.classLevel(uid).name
}

const teacherNameFromUid = (uid) => {
	if (!uid) 
	{
		return 'Missing instructor'
	}
	return store.getters.teacher(uid).name
}

const dancerNameFromUid = (uid) => {
	if (!uid)
	{
		return 'Missing dancer'
	}

	const data = store.getters.dancer(uid)
	if (!data)
	{
		console.warn(`Unknown dancer with uid (${uid}). Might be archived?`)
		return uid
	}
	return `${data.firstName} ${data.lastName}`
}

export {
	studioNameFromUid,
	classTypeFromUid,
	classLevelFromUid,
	teacherNameFromUid,
	dancerNameFromUid
}
