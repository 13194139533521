import {
	auth,
} from '../firebase'

import memoize from 'lodash/memoize'

import events from 'studio-shared/dataLoader/events'

const keyForDate = require('studio-shared/utils/dateToYearMonthDay')

import fetchClassTypes from 'studio-shared/dataLoader/fetchClassTypes'
import fetchClassLevels from 'studio-shared/dataLoader/fetchClassLevels'
import fetchTeachers from 'studio-shared/dataLoader/fetchTeachers'
import fetchStudios from 'studio-shared/dataLoader/fetchStudios'

import fetchAndListenForDancers from 'studio-shared/dataLoader/fetchAndListenForDancers'
import fetchAndListenForDancerEvents from 'studio-shared/dataLoader/fetchAndListenForDancerEvents'
import fetchAndListenForClasses from 'studio-shared/dataLoader/fetchAndListenForClasses'
import fetchAndListenForDancersToClasses from 'studio-shared/dataLoader/fetchAndListenForDancersToClasses'
import fetchAndListenForMissedClasses from 'studio-shared/dataLoader/fetchAndListenForMissedClasses'
import fetchAndListenForMakeups from 'studio-shared/dataLoader/fetchAndListenForMakeups'
import fetchAndListenForExtraCredits from 'studio-shared/dataLoader/fetchAndListenForExtraCredits'
import fetchAndListenForFreeClasses from 'studio-shared/dataLoader/fetchAndListenForFreeClasses'

import fetchAndListenForMissedClassesByDate from 'studio-shared/dataLoader/fetchAndListenForMissedClassesByDate'
import fetchAndListenForMakeupsByDate from 'studio-shared/dataLoader/fetchAndListenForMakeupsByDate'
import fetchAndListenForFreeClassesByDate from 'studio-shared/dataLoader/fetchAndListenForFreeClassesByDate'
import fetchAndListenForDancerEventsByClass from 'studio-shared/dataLoader/fetchAndListenForDancerEventsByClass'

import fetchAndListenForBusinessMetrics from 'studio-shared/dataLoader/fetchAndListenForBusinessMetrics'
import fetchAndListenForBilling from 'studio-shared/dataLoader/fetchAndListenForBilling'
import fetchAndListenForClosures from 'studio-shared/dataLoader/fetchAndListenForClosures'

import fetchAndListenForArchivedDancerEvents from './fetchAndListenForArchivedDancerEvents'
import fetchAndListenForSubstitutes from './fetchAndListenForSubstitutes'
import fetchAndListenForReleves from './fetchAndListenForReleves'

const loader = {
	fetchClassTypes: fetchClassTypes,
	fetchClassLevels: fetchClassLevels,
	fetchTeachers: fetchTeachers,
	fetchStudios: fetchStudios,

	fetchAndListenForDancers: memoize(fetchAndListenForDancers),
	fetchAndListenForDancerEvents: memoize(fetchAndListenForDancerEvents),
	fetchAndListenForClasses: memoize(fetchAndListenForClasses),
	fetchAndListenForDancersToClasses: fetchAndListenForDancersToClasses,
	fetchAndListenForMissedClasses: memoize(fetchAndListenForMissedClasses),
	fetchAndListenForMakeups: memoize(fetchAndListenForMakeups),
	fetchAndListenForExtraCredits: memoize(fetchAndListenForExtraCredits),
	fetchAndListenForFreeClasses: memoize(fetchAndListenForFreeClasses),

	fetchAndListenForMissedClassesByDate: memoize(fetchAndListenForMissedClassesByDate, keyForDate),
	fetchAndListenForMakeupsByDate: memoize(fetchAndListenForMakeupsByDate, keyForDate),
	fetchAndListenForFreeClassesByDate: memoize(fetchAndListenForFreeClassesByDate, keyForDate),
	fetchAndListenForDancerEventsByClass: memoize(fetchAndListenForDancerEventsByClass),

	fetchAndListenForBusinessMetrics: memoize(fetchAndListenForBusinessMetrics),

	fetchAndListenForBilling: memoize(fetchAndListenForBilling),
	fetchAndListenForClosures: memoize(fetchAndListenForClosures),

	fetchAndListenForArchivedDancerEvents: memoize(fetchAndListenForArchivedDancerEvents),
	fetchAndListenForSubstitutes: memoize(fetchAndListenForSubstitutes),
	fetchAndListenForReleves: memoize(fetchAndListenForReleves),

	once: function (eventName) {
		return events.once(eventName)
	},

	initForAdmin: () => {
		auth.onAuthStateChanged(user => {
			if (!user) {
				// signed out
				return
			}

			user.getIdTokenResult().then(result => {
				if (!result.claims.admin) {
					return Promise.reject()
				}
			})
			.then(() => {
				// get dancer to classes and listen for updates
				loader.fetchAndListenForDancersToClasses()

				// get all data and then get classes
				loader.fetchAndListenForClasses().then(() => {

					// get dancers and listen for updates
					loader.fetchAndListenForDancers(data => {
						// NOTE: this is what it needs to be after taking from studio-shared
						loader.fetchAndListenForDancerEvents(data.uid)
					})
				})
			})
			.catch(console.error)
		})
	}
}


export default loader
