import { releves } from '@/database'

import store from '../store'

export default () => {
	return new Promise(resolve => {
		releves.onSnapshot(snap => {
			snap.docChanges().forEach(change => {
				const doc = change.doc

				if (doc.id === '_details')
				{
					return
				}

				const pledge = doc.data()
				pledge.uid = doc.id

				switch (change.type)
				{
					case 'added':
						store.commit('releves/add', pledge)
						break

					case 'modified':
						store.commit('releves/update', pledge)
						break

					case 'removed':
						store.commit('releves/remove', pledge)
						break
				}
			})

			resolve()
		})
	})
}
