import {
	firestore as db,
	collections
} from '../firebase'

import store from '../store'

import toMoment from 'studio-shared/utils/toMoment'

export default (dancerId) => {
	return new Promise(resolve => {
		db.collection(collections.DancerEvents).doc(dancerId).collection('events-archived').onSnapshot(snap => {
			snap.docChanges().forEach(change => {
				const doc = change.doc
				const event = doc.data()
				event.uid = doc.id

				if (event.effectiveDate) 
				{
					event.effectiveDate = toMoment(event.effectiveDate).toDate()
				}

				const data = {
					event: event,
					dancerId: dancerId
				}

				if (change.type === 'added') {
					store.commit('archivedEvents/add', data)
					return
				}

				if (change.type === 'modified') {
					store.commit('archivedEvents/update', data)
					return
				}

				if (change.type === 'removed') {
					store.commit('archivedEvents/remove', data)
					return
				}
			})

			resolve()
		})
	})
}
